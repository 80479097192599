<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">APDP</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Tableau de Bord</a></li>
              <li class="breadcrumb-item active" aria-current="page">Acceuil</li>
            </ol>
          </nav>
        </div>
        <!-- <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">Nouveau</base-button>
          <base-button size="sm" type="neutral">Filtre</base-button>
        </div> -->
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-primary border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Approuvées</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">8/24</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="30"></base-progress>
                </div>
                <div class="col-auto">
                  <base-dropdown title-classes="btn btn-sm btn-neutral mr-0"
                                 menu-on-right
                                 :has-toggle="false">

                    <template slot="title">
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <a class="dropdown-item" href="#">Voir demandes</a>
                    <a class="dropdown-item" href="#">Demandes en cours</a>
                    <a class="dropdown-item" href="#">Demandes rejetées</a>
                  </base-dropdown>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">Voir les détails</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-info border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Soumises</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">123/267</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="50"></base-progress>
                </div>
                <div class="col-auto">
                  <base-dropdown title-classes="btn btn-sm btn-neutral mr-0"
                                 menu-on-right
                                 :has-toggle="false">

                    <template slot="title">
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Autre action</a>
                  </base-dropdown>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">Voir les détails</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-danger border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Traitées</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">200/300</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="80"></base-progress>
                </div>
                <div class="col-auto">
                  <base-dropdown title-classes="btn btn-sm btn-neutral mr-0"
                                 menu-on-right
                                 :has-toggle="false">

                    <template slot="title">
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Autres action</a>
                  </base-dropdown>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">Voir les détails</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-gradient-default border-0">
            <!-- Card body -->
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0 text-white">Notifications</h5>
                  <span class="h2 font-weight-bold mb-0 text-white">50/62</span>
                  <base-progress class="progress-xs mt-3 mb-0" type="success" :value="90"></base-progress>
                </div>
                <div class="col-auto">
                  <base-dropdown title-classes="btn btn-sm btn-neutral mr-0"
                                 menu-on-right
                                 :has-toggle="false">

                    <template slot="title">
                      <i class="fas fa-ellipsis-h"></i>
                    </template>

                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Autre action</a>
                  </base-dropdown>
                </div>
              </div>
              <p class="mt-3 mb-0 text-sm">
                <a href="#!" class="text-nowrap text-white font-weight-600">Voir les détails</a>
              </p>
            </div>
          </div>
        </div>
      </div>

    </base-header>

    <!--Charts-->
    <div class="vh-100">
      
    </div>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';

  // Components
  import BaseHeader from '@/components/BaseHeader';
  import BaseProgress from '@/components/BaseProgress';

  export default {
    components: {
      BaseHeader,
      BaseProgress,
    },
    data() {
      return {
        bigLineChart: {
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          }
        }
      };
    }
  };
</script>
<style></style>
